import React, { useEffect } from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  EmailField,
  SelectInput,
  PasswordInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import Icon from '@mui/icons-material/SupervisedUserCircle';
import { useLocation } from 'react-router-dom';

export const UsersIcon = Icon;

export const UsersList = (props: any) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="phone" />
      <TextField source="user_type" />
      <EditButton />
    </Datagrid>
  </List>
);

const UserTitle = ({ record }: { record?: any }) => {
  return <span>User {record ? `"${record.name}"` : ''}</span>;
};

export const UsersEdit = (props: any) => (
  <Edit title={<UserTitle />} {...props}>
    <SimpleForm>
      <UserForm isEdit />
    </SimpleForm>
  </Edit>
);

export const UsersCreate = (props: any) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <UserForm />
      </SimpleForm>
    </Create>
  );
};

const UserForm = ({ isEdit = false }: { isEdit?: boolean }) => {
  const location = useLocation();

  const formContext = useFormContext();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const defaultValues = {
      name: searchParams.get('name') || '',
      email: searchParams.get('email') || '',
      password: searchParams.get('password') || '',
      phone: searchParams.get('phone') || '',
      user_type: searchParams.get('userType') || '',
    };

    // Set default values and mark them as dirty
    Object.keys(defaultValues).forEach((key) => {
      const value = defaultValues[key];
      if (value) {
        formContext.setValue(key, value, { shouldDirty: true });
      }
    });
  }, [location.search, formContext]);

  return (
    <>
      <TextInput source="name" />
      <TextInput source="email" />
      {isEdit ? (
        <PasswordInput source="new_password" />
      ) : (
        <PasswordInput source="password" />
      )}
      <TextInput source="phone" />
      <SelectInput
        source="user_type"
        choices={[
          { id: 'Admin', name: 'Admin' },
          { id: 'Promoter', name: 'Promoter' },
          { id: 'VenueOwner', name: 'VenueOwner' },
          { id: 'Artist', name: 'Artist' },
        ]}
      />
    </>
  );
};
