import { Box, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingPage } from 'react-admin';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import EventsService, {
  setRepeatingEventNextDate,
} from '../../services/Events';
import EventCard from '../EventCard';
import { EventImage } from '../EventsShared';
import { initGoogleAds } from '../Utils';
import EventMiniCard from '../EventMiniCard';

const EventListingPage = () => {
  const { id }: { id?: number } = useParams();
  const [event, setEvent] = useState<any>(null);
  const [relatedEvents, setRelatedEvents] = useState<any[]>([]);

  useEffect(() => {
    if (!id) {
      return;
    }

    const fetchEventData = async () => {
      try {
        const eventData = await EventsService.getEvent(id);
        setEvent(setRepeatingEventNextDate(eventData));
      } catch (error) {
        console.error('Failed to fetch event data:', error);
      }
    };

    const fetchRelatedEvents = async () => {
      try {
        const related = await EventsService.getRelatedEvents(id);
        setRelatedEvents(related);
      } catch (error) {
        console.error('Failed to fetch related events:', error);
      }
    };

    fetchEventData();
    fetchRelatedEvents();
  }, [id]);

  useEffect(() => {
    if (event) {
      initGoogleAds();
    }
  }, [event]);

  if (event === null) {
    return (
      <Box>
        <LoadingPage
          loadingPrimary="Loading..."
          loadingSecondary="Please wait while we load the event details!"
        />
      </Box>
    );
  }

  const handleEventClick = (eventId) => {
    window.location.href = `/event/${eventId}`;
  };

  return (
    <Box mt={4} p={2}>
      {/* @ts-ignore */}
      <Helmet>
        <title>
          {event.title} at {event.venue?.name || ''}- RogueValley.Events
        </title>
        <meta name="description" content={event.description} />
        <meta
          name="keywords"
          content={`Rogue Valley, Southern Oregon, events, local events, ${
            event.title
          }, ${event.tags.join(',')}`}
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="RogueValley.Events" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${event.title} - RogueValley.Events`}
        />
        <meta property="og:description" content={event.description} />
        <meta
          property="og:url"
          content={`https://roguevalley.events/event/${id}`}
        />
        <meta property="og:image" content={event.poster_image_url} />
        <meta property="og:site_name" content="RogueValley.Events" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${event.title} - RogueValley.Events`}
        />
        <meta name="twitter:description" content={event.description} />
        <meta name="twitter:image" content={event.poster_image_url} />
        <link rel="canonical" href={`https://roguevalley.events/event/${id}`} />
      </Helmet>
      <Paper>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          gap={2}
          p={2}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
            flex={1}
          >
            <EventImage event={event} flex={1} />
          </Box>
          <EventCard event={event} />
        </Box>
      </Paper>
      <Box my={2}>
        <ins
          className="ADSENSE"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-2902939122125433"
          data-ad-slot="3356509899"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </Box>
      <Box mt={4}>
        <Typography variant="h4" gutterBottom>
          Other Upcoming Events
        </Typography>
        <Grid container spacing={4}>
          {relatedEvents.map((relatedEvent) => (
            <Grid item xs={12} sm={6} md={4} key={relatedEvent.id}>
              <Paper
                sx={{
                  cursor: 'pointer',
                  height: '100%',
                  p: 2,
                }}
                onClick={() => handleEventClick(relatedEvent.id)}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box
                    component="img"
                    src={relatedEvent.poster_image_url}
                    alt={relatedEvent.title}
                    sx={{ width: '100%', height: 'auto', mb: 0 }}
                  />
                  <EventMiniCard event={relatedEvent} showImage={false} />
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default EventListingPage;
