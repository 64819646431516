import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ViewListIcon from '@mui/icons-material/ViewList';
import {
  Box,
  Container,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EventFilter } from '../components/EventFilter';
import EventsCalendar from '../components/EventsCalendar';
import { EventsList } from '../components/EventsList';
import TopArtists from '../components/TopArtists';
import TopVenues from '../components/TopVenues';
import { CTAButton, initGoogleAds } from '../components/Utils';
import { SEOLocalityTitle } from '../Constants';
import { Event as EventType } from '../types';
import { useEventsContext } from '../components/EventsContext';

export type GroupedEventsType = {
  weekendEvents?: EventType[];
  upcomingEvents?: EventType[];
  featuredEvents?: EventType[];
  pastEvents?: EventType[];
};

type MainProps = { showTagHeader?: boolean };

export const Main: React.FC<MainProps> = ({ showTagHeader }) => {
  const accessToken = localStorage.getItem('access_token');
  const { events } = useEventsContext();
  const { tag }: { tag?: string } = useParams();
  const [viewMode, setViewMode] = useState<'list' | 'calendar'>('list');

  useEffect(() => {
    if (!events) {
      return;
    }
    initGoogleAds();
  }, [events]);

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: 'list' | 'calendar' | null,
  ) => {
    if (newView !== null) {
      setViewMode(newView);
    }
  };

  return (
    <Box>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <CTAButton
              href={
                accessToken ? '/manage/Events/create' : '/contact#contact-us'
              }
              text="List Your Event!"
              fullWidth
              mb={2}
            />
            <EventFilter showTagHeader={showTagHeader} />
            <Box sx={{ display: { xs: 'none', md: 'inherit' } }}>
              <TopArtists />
              <Box
                sx={{
                  borderBottom: (theme) =>
                    `4px double ${theme?.palette?.ctaColor?.main}`,
                  mb: 2,
                }}
              ></Box>
              <TopVenues />
              <Box mt={2}>
                <ins
                  className="ADSENSE"
                  style={{ display: 'block' }}
                  data-ad-client="ca-pub-2902939122125433"
                  data-ad-slot="7395094731"
                  data-ad-format="auto"
                  data-full-width-responsive="true"
                ></ins>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            {showTagHeader && (
              <Typography variant="h1" textAlign="center">
                {SEOLocalityTitle}{' '}
                <Box component="em" sx={{ textTransform: 'capitalize' }}>
                  {tag}
                </Box>{' '}
                Events
              </Typography>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={handleViewChange}
                aria-label="view mode"
              >
                <ToggleButton value="list" aria-label="list view">
                  <ViewListIcon />
                </ToggleButton>
                <ToggleButton value="calendar" aria-label="calendar view">
                  <CalendarMonthIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <EventsList viewMode={viewMode} />
            {viewMode === 'calendar' && <EventsCalendar />}
          </Grid>

          <Grid item xs={12} sx={{ display: { xs: 'inherit', md: 'none' } }}>
            <Box width="100%">
              <Box
                sx={{
                  borderBottom: (theme) =>
                    `4px double ${theme?.palette?.ctaColor?.main}`,
                  mb: 2,
                }}
              ></Box>
              <TopArtists />
              <Box
                sx={{
                  borderBottom: (theme) =>
                    `4px double ${theme?.palette?.ctaColor?.main}`,
                  mb: 2,
                }}
              ></Box>
              <TopVenues />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
