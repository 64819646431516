import { Typography, Box, Card, CardContent, Chip } from '@mui/material';
import { formatDate } from './Utils';
import { EventImage } from './EventsShared';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link as RouterLink } from 'react-router-dom';
import { Event as EventType, Artist, Tag } from '../types';

const EventMiniCard = ({
  event,
  showImage = true,
}: {
  event: EventType;
  showImage?: boolean;
}) => {
  return (
    <Card
      key={event.id}
      sx={{
        display: 'flex',
        marginBottom: showImage ? 2 : 0,
        boxShadow: showImage ? 1 : 0,
        backgroundColor: showImage ? 'background.paper' : 'transparent',
        width: showImage ? 'auto' : '100%',
        textAlign: showImage ? 'left' : 'center',
      }}
    >
      {showImage && <EventImage event={event} sx={{ width: '200px' }} />}
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <CardContent
          sx={{
            flex: '1 0 auto',
            width: '100%',
            pb: showImage ? 2 : '0 !important',
          }}
        >
          <RouterLink to={`/event/${event.id}`}>
            <Typography variant="h5">{event.title}</Typography>
          </RouterLink>

          <Typography variant="subtitle1" color="textSecondary">
            {formatDate(event.datetime_start)}
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            display="flex"
            alignItems="center"
            textAlign="center"
            justifyContent={showImage ? 'left' : 'center'}
          >
            <LocationOnIcon fontSize="small" />
            <strong>{event.venue?.name}</strong>
          </Typography>
          {event.artists?.length > 0 && (
            <Box mt={1}>
              {event.artists.map((artist: Artist) => (
                <Chip
                  key={artist.id}
                  label={
                    <Box className="limited-text-small">{artist.name}</Box>
                  }
                  size="small"
                  sx={{
                    m: 0.5,
                    backgroundColor: (theme) => theme.palette.ctaColor?.main,
                    color: '#fff',
                  }}
                />
              ))}
            </Box>
          )}
          {event.tags?.length > 0 && (
            <Box mt={1}>
              {event.tags.map((tag: Tag) => (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  size="small"
                  component="a"
                  href={`/events/${tag.name}`}
                  clickable
                  sx={{ textTransform: 'capitalize', mr: 1 }}
                />
              ))}
            </Box>
          )}
        </CardContent>
      </Box>
    </Card>
  );
};

export default EventMiniCard;
