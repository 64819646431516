import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { Event as EventType } from '../types';
import { GroupedEventsType } from '../pages/Main';
import EventsService from '../services/Events';
import groupEventsByUpcoming from '../utilities/Events';
import { FiltersType, DefaultFilters } from '../components/EventFilter';
import { useParams } from 'react-router-dom';

interface EventsContextType {
  filters: FiltersType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  events: GroupedEventsType | null;
  filteredEvents: GroupedEventsType | null;
  setFilteredEvents: React.Dispatch<
    React.SetStateAction<GroupedEventsType | null>
  >;
  setEvents: React.Dispatch<React.SetStateAction<GroupedEventsType | null>>;
}

const EventsContext = createContext<EventsContextType | undefined>(undefined);

export const EventsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { tag }: { tag?: string } = useParams();
  const [filters, setFilters] = useState<FiltersType>(
    tag ? { ...DefaultFilters, tag } : DefaultFilters,
  );
  const [events, setEvents] = useState<GroupedEventsType | null>(null);
  const [filteredEvents, setFilteredEvents] =
    useState<GroupedEventsType | null>(null);

  const fetchEvents = async () => {
    const eventsData: EventType[] = await EventsService.getEvents();
    const groupedEventsData = groupEventsByUpcoming(eventsData);
    setEvents(groupedEventsData);
  };

  const filterEventsByField = useCallback(
    (events: EventType[]) => {
      return events.filter((event) => {
        return (
          (filters.artist === '' ||
            event.artists.some((artist) =>
              artist.name.toLowerCase().includes(filters.artist.toLowerCase()),
            )) &&
          (filters.city === '' ||
            event.venue.city
              .toLowerCase()
              .includes(filters.city.toLowerCase())) &&
          (filters.venue === '' ||
            event.venue.name
              .toLowerCase()
              .includes(filters.venue.toLowerCase())) &&
          (filters.tag === '' ||
            event.tags.some((tag) =>
              tag.name.toLowerCase().includes(filters.tag.toLowerCase()),
            )) &&
          (filters.date === '' ||
            new Date(event.datetime_start).toLocaleDateString('en-CA') ===
              filters.date)
        );
      });
    },
    [filters],
  );

  const updateFilteredEvents = useCallback(() => {
    const tempEvents: GroupedEventsType | {} = { ...(events || {}) };
    Object.keys(tempEvents).forEach((key) => {
      tempEvents[key] = filterEventsByField(tempEvents[key]);
    });
    setFilteredEvents(tempEvents);
  }, [events, filterEventsByField]);

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    updateFilteredEvents();
  }, [updateFilteredEvents]);

  return (
    <EventsContext.Provider
      value={{
        filters,
        setFilters,
        events,
        filteredEvents,
        setFilteredEvents,
        setEvents,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export const useEventsContext = () => {
  const context = useContext(EventsContext);
  if (!context) {
    throw new Error('useEventsContext must be used within an EventsProvider');
  }
  return context;
};
