import { Box } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Event as EventType } from '../types';
import SelectedEvent from './SelectedEvent';
import { useEventsContext } from './EventsContext';

interface EventsCalendarProps {}

const localizer = momentLocalizer(moment);

const EventsCalendar: React.FC<EventsCalendarProps> = () => {
  const { filteredEvents } = useEventsContext();
  const [selectedEvent, setSelectedEvent] = useState<EventType | null>(null);
  const [previousUrl] = useState(window.location.href);

  const handleEventClick = (calendarEvent: any) => {
    // Access the original event data from the resource property
    const originalEvent = calendarEvent.resource;
    if (originalEvent) {
      setSelectedEvent(originalEvent);
      window.history.pushState(null, 'Event', `/event/${originalEvent.id}`);
    }
  };

  const handleCloseEventDetails = () => {
    setSelectedEvent(null);
    window.history.pushState(null, 'Homepage', previousUrl);
  };

  // Transform events for calendar display
  const allEvents = filteredEvents
    ? [
        ...(filteredEvents.weekendEvents || []),
        ...(filteredEvents.upcomingEvents || []),
      ]
    : [];
  const calendarEvents =
    allEvents?.map((event) => ({
      id: event.id,
      title: event.title,
      start: new Date(event.datetime_start),
      end: new Date(event.datetime_end),
      resource: event, // Store the original event data
    })) || [];

  const dayPropGetter = (date: Date) => {
    if (moment(date).isBefore(moment(), 'day')) {
      return {
        style: {
          backgroundColor: '#f0f0f0', // Light grey color
        },
      };
    }
    return {};
  };

  return (
    <Box>
      <div style={{ height: '80vh' }}>
        <Calendar
          localizer={localizer}
          events={calendarEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: '100%' }}
          onSelectEvent={handleEventClick}
          views={['month', 'week', 'day']}
          defaultView="month"
          dayPropGetter={dayPropGetter}
        />
      </div>

      {selectedEvent && (
        <SelectedEvent
          selectedEvent={selectedEvent}
          handleCloseEventDetails={handleCloseEventDetails}
        />
      )}
    </Box>
  );
};

export default EventsCalendar;
