import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Artist, Event as EventType } from '../types';
import EventCard from "./EventCard";
import { EventImage } from "./EventsShared";

const SelectedEvent = (props: {
  selectedEvent: EventType;
  handleCloseEventDetails: any;
}) => {
  const { selectedEvent, handleCloseEventDetails } = props;
  if (!selectedEvent) return null;
  return (
    <Dialog
      open={true}
      onClose={handleCloseEventDetails}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {handleCloseEventDetails ? (
          <IconButton
            aria-label="close"
            onClick={handleCloseEventDetails}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems="flex-start"
          gap={2}
        >
          {(selectedEvent.poster_image_url ||
            selectedEvent.artists.filter((artist: Artist) => artist.image_url)
              .length > 0 ||
            selectedEvent.venue['logo_image_url']) && (
            <Box flex={1}>
              <EventImage event={selectedEvent} />
            </Box>
          )}
          <EventCard event={selectedEvent} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SelectedEvent;
