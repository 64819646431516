import { Box, Button, Divider, Link, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingPage } from 'react-admin';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import VenuesService from '../services/Venues';
import { Event as EventType } from '../types';
import EventMiniCard from './EventMiniCard';
import TextWithLinks from './TextWithLInks';
import { Facebook, Instagram, Twitter } from '@mui/icons-material';
import { GoogleMapsApiKey } from '../App';
import { initGoogleAds } from './Utils';

const VenueDetail = () => {
  const { id }: { id?: number } = useParams();
  const navigate = useNavigate();
  const [venue, setVenue] = useState<any>(null);
  const [upcomingEvents, setUpcomingEvents] = useState<any[]>([]);
  const [pastEvents, setPastEvents] = useState<any[]>([]);

  useEffect(() => {
    if (!id) {
      return;
    }
    const fetchVenue = async () => {
      try {
        const venueData = await VenuesService.getVenue(id);
        if (!venueData.data) {
          navigate('/');
          return;
        }
        setVenue(venueData.data);
        setUpcomingEvents(
          venueData.data.events
            .filter(
              (event: EventType) => new Date(event.datetime_start) > new Date(),
            )
            .sort(
              (a: EventType, b: EventType) =>
                new Date(a.datetime_start).getTime() -
                new Date(b.datetime_start).getTime(),
            ),
        );
        setPastEvents(
          venueData.data.events
            .filter(
              (event: EventType) => new Date(event.datetime_end) < new Date(),
            )
            .sort(
              (a: EventType, b: EventType) =>
                new Date(b.datetime_start).getTime() -
                new Date(a.datetime_start).getTime(),
            ),
        );
      } catch (error) {
        console.error('Failed to fetch venue:', error);
        navigate('/');
      }
    };
    fetchVenue();
  }, [id, navigate]);

  useEffect(() => {
    if (!venue) {
      return;
    }
    initGoogleAds();
  }, [venue]);

  if (venue === null) {
    return (
      <Box>
        <LoadingPage
          loadingPrimary="Loading..."
          loadingSecondary="Please wait while we load the venue details!"
        />
      </Box>
    );
  }

  return (
    <Box mt={4} p={2}>
      {/* @ts-ignore */}
      <Helmet>
        <title>{venue.name} - RogueValley.Events</title>
        <meta name="description" content={venue.description} />
        <meta
          name="keywords"
          content={`Rogue Valley, Southern Oregon, venues, local venues, ${venue.name}`}
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="RogueValley.Events" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${venue.name} - RogueValley.Events`}
        />
        <meta property="og:description" content={venue.description} />
        <meta
          property="og:url"
          content={`https://roguevalley.events/venue/${id}`}
        />
        <meta property="og:image" content={venue.logo_image_url} />
      </Helmet>
      <Paper>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          gap={2}
          p={2}
        >
          <Box flex="1">
            <Box display="flex" flexDirection="row">
              {venue.logo_image_url && (
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="center"
                  flex={1}
                  mr={2}
                  sx={{ maxWidth: '300px' }}
                >
                  <img
                    src={venue.logo_image_url}
                    alt={venue.name}
                    style={{ maxWidth: '300px' }}
                  />
                </Box>
              )}
              <Box>
                <Typography variant="h1" mb={1}>
                  {venue.name}
                </Typography>
                {venue.description && (
                  <Typography variant="body2" color="textSecondary">
                    Description: <TextWithLinks textValue={venue.description} />
                  </Typography>
                )}
                <Typography variant="body2" color="textSecondary">
                  Address:{' '}
                  <Link
                    href={`https://www.google.com/maps/search/?api=1&query=${venue.geocoded_address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: (theme) => theme.palette.ctaColor?.main }}
                  >
                    {venue.geocoded_address}
                  </Link>
                </Typography>
                {venue.phone && (
                  <Typography variant="body2" color="textSecondary">
                    Phone: {venue.phone}
                  </Typography>
                )}
                {venue.website && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                  >
                    Website:&nbsp;
                    <Link
                      target="_blank"
                      href={venue.website}
                      className="limited-text"
                      display="inline-block"
                      sx={{ color: (theme) => theme.palette.ctaColor?.main }}
                    >
                      {venue.website}
                    </Link>
                  </Typography>
                )}
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                >
                  {venue.twitter_username && (
                    <Link
                      target="_blank"
                      href={`https://www.twitter.com/${venue.twitter_username}`}
                      color="ctaColor.main"
                    >
                      <Twitter />
                    </Link>
                  )}
                  {venue.instagram_username && (
                    <Link
                      target="_blank"
                      href={`https://www.instagram.com/${venue.instagram_username}`}
                      color="ctaColor.main"
                    >
                      <Instagram />
                    </Link>
                  )}
                  {venue.facebook_username && (
                    <Link
                      target="_blank"
                      href={`https://www.facebook.com/${venue.facebook_username}`}
                      color="ctaColor.main"
                    >
                      <Facebook />
                    </Link>
                  )}
                </Box>
              </Box>
            </Box>

            <Box mt={2} mb={2}>
              <iframe
                title="Google Map"
                width="100%"
                height="300"
                frameBorder="0"
                style={{ border: 0 }}
                src={`https://www.google.com/maps/embed/v1/place?key=${GoogleMapsApiKey}&q=${encodeURIComponent(
                  `${venue.geocoded_address}`,
                )}`}
                allowFullScreen
              ></iframe>
            </Box>
            <Box my={2}>
              <ins
                className="ADSENSE"
                style={{ display: 'block' }}
                data-ad-client="ca-pub-2902939122125433"
                data-ad-slot="3356509899"
                data-ad-format="auto"
                data-full-width-responsive="true"
              ></ins>
            </Box>
            <Divider sx={{ my: 2 }} />
            {upcomingEvents.length > 0 && (
              <>
                <Typography variant="h4">Upcoming Events</Typography>
                {upcomingEvents.map((event) => {
                  return <EventMiniCard key={event.id} event={event} />;
                })}
              </>
            )}

            {pastEvents.length > 0 && (
              <>
                <Typography variant="h4">Past Events</Typography>
                {pastEvents.map((event) => {
                  return <EventMiniCard key={event.id} event={event} />;
                })}
              </>
            )}
          </Box>
        </Box>
      </Paper>
      <Button
        variant="contained"
        href="/venues"
        component={Link}
        p={1}
        mt={'8px'}
        sx={{
          backgroundColor: (theme) => theme.palette.ctaColor?.main,
          color: '#fff',
          fontFamily: '"kon-tiki-aloha-jf", sans-serif',
          mb: 2,
          mt: 2,
          fontSize: '1.2em',
          width: '100%',
        }}
      >
        View Other Venues
      </Button>
    </Box>
  );
};

export default VenueDetail;
