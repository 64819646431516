import crudProvider from 'ra-data-nestjsx-crud/lib';
import CustomFetch from './CustomFetch';
import { GetOneParams, CreateParams, UpdateParams } from 'react-admin';
import ArtistsService from '../../services/Artists';
import EventsService from '../../services/Events';
import TagsService from '../../services/Tags';
import VenuesService from '../../services/Venues';
import { Tag, Artist } from '../../types';

export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_TEST = process.env.REACT_APP_IS_TEST;
export const API_ROOT = IS_DEV
  ? process.env.REACT_APP_IS_TEST
    ? 'http://localhost:4002'
    : 'http://localhost:4001'
  : '/api';
export const ADMIN_API_ROOT = `${API_ROOT}/admin`;

export const dataProvider = crudProvider(ADMIN_API_ROOT, CustomFetch);
export const regularDataProvider = crudProvider(API_ROOT);

export const CustomDataProvider = {
  ...dataProvider,
  getOne: async (resource: string, params: GetOneParams<any>) => {
    if (resource === 'Events') {
      const result = await dataProvider.getOne(resource, params);
      const { tags = [], artists = [], ...data } = result.data;
      const tag_ids = tags.map((tag: Tag) => tag.id);
      const artist_ids = artists.map((artist: Artist) => artist.id);
      return {
        ...result,
        data: {
          ...data,
          tags: tag_ids,
          artists: artist_ids,
        },
      };
    }
    return await dataProvider.getOne(resource, params);
  },
  create: async (resource: string, params: CreateParams<any>) => {
    switch (resource) {
      case 'Events':
        params.data.tags = params.data.tags?.map((tag_id: number) => ({
          id: tag_id,
        }));
        params.data.artists = params.data.artists?.map((artist_id: number) => ({
          id: artist_id,
        }));
        return await EventsService.handleCreate(params);
      case 'Venues':
        return await VenuesService.handleCreate(params);
      case 'Tags':
        return await TagsService.handleCreate(params);
      case 'Artists':
        return await ArtistsService.handleCreate(params);
      default:
        return dataProvider.create(resource, params);
    }
  },
  update: async (resource: string, params: UpdateParams<any>) => {
    switch (resource) {
      case 'Events':
        params.data.tags = params.data.tags?.map((tag_id: number) => ({
          id: tag_id,
        }));
        params.data.artists = params.data.artists?.map((artist_id: number) => ({
          id: artist_id,
        }));
        return await EventsService.handleUpdate(params);
      case 'Venues':
        return await VenuesService.handleUpdate(params);
      case 'Artists':
        return await ArtistsService.handleUpdate(params);
      default:
        return dataProvider.update(resource, params);
    }
  },
};
