import { useEffect, useRef } from 'react';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  ImageInput,
  useRecordContext,
  ImageField,
  useCreateSuggestionContext,
  usePermissions,
  SaveButton,
  UrlField,
  AutocompleteInput,
  Filter,
  ReferenceInput,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Dialog, TextField as MuiTextField, Tooltip } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import { Autocomplete } from '@react-google-maps/api';
import { useState, useCallback } from 'react';
import VenuesService from '../../services/Venues';
import CustomAuthProvider from '../../components/admin/CustomAuthProvider';

export const VenueIcon = BusinessIcon;
interface VenuesFilterProps {
  [key: string]: any;
}

export const VenuesFilter = (props: VenuesFilterProps) => (
  <Filter {...props}>
    <ReferenceInput
      label="Venue Name"
      source="id"
      reference="venues"
      sort={{ field: 'name', order: 'ASC' }}
      perPage={10000}
      alwaysOn
    >
      <AutocompleteInput
        label="Venue Name"
        optionText="name"
        filterToQuery={(searchText) => ({ name: searchText })}
      />
    </ReferenceInput>
  </Filter>
);

const getFiltersForRole = (user) => {
  if (!user) return {};
  let searchFilter = {};
  let orFilters: any[] = [];
  switch (user.user_type) {
    case 'Admin':
      // Admins can see all venues, so no filter is applied
      return {};
    case 'VenueOwner':
    case 'Promoter':
    case 'Artist':
    default:
      const managedVenues = user.managed_venues || [];
      managedVenues.push({ venue_id: 0 });
      orFilters.push({
        id: { $in: managedVenues.map((mv) => mv.venue_id) },
      });
      break;
  }

  searchFilter = {
    $or: orFilters,
  };
  return {
    q: { s: JSON.stringify(searchFilter) },
  };
};

export const VenueList = () => {
  const { permissions } = usePermissions();
  const [currentUser, setCurrentUser] = useState<null | any>(null);
  const filters = getFiltersForRole(currentUser);

  useEffect(() => {
    CustomAuthProvider.getUserDetails()
      .then((user) => setCurrentUser(user))
      .catch((error) => console.error('Error fetching user details:', error));
  }, []);

  if (!currentUser) {
    return null;
  }

  return (
    <List
      filter={filters}
      filters={permissions === 'Admin' ? <VenuesFilter /> : undefined}
    >
      <Datagrid bulkActionButtons={permissions === 'Admin' ? undefined : false}>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="geocoded_address" />
        <TextField source="city" />
        <TextField source="state" />
        <TextField source="phone" />
        <UrlField source="website" />
        <TextField source="point" />
        <ImageField source="logo_image_url" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const VenueName = () => {
  const record = useRecordContext();
  return <span>Venue {record ? `"${record.name}"` : ''}</span>;
};

export const VenueEdit = () => {
  return (
    <Edit title={<VenueName />} mutationMode="pessimistic">
      <SimpleForm>
        <VenueForm showSearch={false} />
      </SimpleForm>
    </Edit>
  );
};

export const VenueCreate = () => {
  return (
    <Create title="Create a Venue">
      <SimpleForm>
        <VenueForm />
      </SimpleForm>
    </Create>
  );
};

export const VenueForm = (props: any) => {
  const { defaultName = null, showSearch = true } = props;
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [placeSelected, setPlaceSelected] = useState<boolean>(!showSearch);
  const formContext = useFormContext();
  const searchRef = useRef<HTMLInputElement>(null);

  const handleLoad = useCallback((newAutocomplete: any) => {
    setAutocomplete(newAutocomplete);
  }, []);

  const handlePlaceChanged = () => {
    if (!autocomplete) return;

    setPlaceSelected(true);

    const place = autocomplete.getPlace();

    const fieldsToUpdate: any = {
      name: place.name || '',
      geocoded_address: place.formatted_address || '',
      city: '',
      state: '',
      phone: place.formatted_phone_number || '',
      website: place.website || '',
      point: { lat: '', lng: '' },
    };

    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'locality':
          fieldsToUpdate.city = component.long_name;
          break;
        case 'administrative_area_level_1':
          fieldsToUpdate.state = component.short_name;
          break;
        default:
          break;
      }
    }

    if (place.geometry && place.geometry.location) {
      fieldsToUpdate.point.lat = place.geometry.location.lat();
      fieldsToUpdate.point.lng = place.geometry.location.lng();
    }

    for (const field in fieldsToUpdate) {
      if (field === 'point') {
        formContext.setValue(
          field,
          `POINT(${fieldsToUpdate[field].lng} ${fieldsToUpdate[field].lat})`,
          { shouldDirty: true },
        );
      } else {
        formContext.setValue(field, fieldsToUpdate[field], {
          shouldDirty: true,
        });
      }
    }
  };

  useEffect(() => {
    if (defaultName) {
      searchRef?.current?.focus();
      // Creating a space key event
      const event = new KeyboardEvent('keydown', {
        key: ' ',
        code: 'Space',
        charCode: 32,
      });

      // Dispatching the event to input
      searchRef?.current?.dispatchEvent(event);
    }
  });

  return (
    <>
      {showSearch && (
        <Tooltip
          title="Search using the venue name first. If the venue isn't found, then try an address."
          placement="top"
        >
          <div>
            <Autocomplete
              onLoad={handleLoad}
              onPlaceChanged={handlePlaceChanged}
              fields={[
                'name',
                'formatted_address',
                'address_components',
                'formatted_phone_number',
                'geometry',
                'website',
              ]}
            >
              <MuiTextField
                inputRef={searchRef}
                label="Search Google for Venue Details"
                defaultValue={defaultName}
                fullWidth
              />
            </Autocomplete>
          </div>
        </Tooltip>
      )}
      <TextInput disabled source="id" fullWidth />
      <ImageInput source="logo_image_url" accept={{ 'image/*': [] }} fullWidth>
        <PreviewImage />
      </ImageInput>
      <TextInput source="name" fullWidth />
      <TextInput source="description" multiline fullWidth />
      <TextInput
        source="geocoded_address"
        inputProps={{ disabled: placeSelected }}
        fullWidth
      />
      <TextInput
        source="city"
        inputProps={{ disabled: placeSelected }}
        fullWidth
      />
      <TextInput
        source="state"
        inputProps={{ disabled: placeSelected }}
        fullWidth
      />
      <TextInput source="phone" fullWidth />
      <TextInput source="website" fullWidth type="url" />
      <TextInput source="facebook_username" fullWidth />
      <TextInput source="instagram_username" fullWidth />
      <TextInput source="twitter_username" fullWidth />
      <TextInput
        source="point"
        sx={{ display: 'none' }}
        inputProps={{ disabled: placeSelected }}
        fullWidth
      />
    </>
  );
};

export const PreviewImage = (props: any) => {
  const venueRecord = useRecordContext(props);
  if (!venueRecord) {
    return null;
  }

  return (
    <img
      width="200"
      src={`${venueRecord.src ? venueRecord.src : venueRecord}`}
      alt="logo"
    />
  );
};

export const CreateVenueDialog = () => {
  const { onCancel, onCreate, filter } = useCreateSuggestionContext();

  const handleSubmit = async (data: any) => {
    const result = await VenuesService.handleCreate({ data });
    onCreate(result.data);
  };
  return (
    <Dialog open onClose={onCancel}>
      <Create>
        <SimpleForm toolbar={false} onSubmit={handleSubmit}>
          <VenueForm defaultName={filter} />
          <SaveButton alwaysEnable={true} />
        </SimpleForm>
      </Create>
    </Dialog>
  );
};
