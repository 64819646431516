import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { API_ROOT } from '../components/admin/CustomDataProvider';

import { FilterDropDown } from './FilterDropDown';
import { useEventsContext } from './EventsContext';

export interface FilterProps {
  showTagHeader?: boolean;
}

export type FiltersType = {
  city: string;
  venue: string;
  tag: string;
  artist: string;
  date: string;
};
export const DefaultFilters: FiltersType = {
  city: '',
  venue: '',
  tag: '',
  artist: '',
  date: '',
};

export const EventFilter: React.FC<FilterProps> = ({ showTagHeader }) => {
  const [cities, setCities] = useState<string[]>([]);
  const [venues, setVenues] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [artists, setArtists] = useState<string[]>([]);
  const { filters, setFilters } = useEventsContext();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${API_ROOT}/events/filters`);
      const data = await response.json();
      setCities(data.cities);
      setVenues(data.venues);
      setTags(data.tags);
      setArtists(data.artists);
    };

    fetchData();
  }, []);

  const handleChange = (event) => {
    const name = event.target.name as keyof typeof filters;
    setFilters({
      ...filters,
      [name]: event.target.value,
    });
  };

  const handleReset = (e) => {
    setFilters(DefaultFilters);
    e.preventDefault();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 1,
        borderBottom: (theme) => `4px double ${theme?.palette?.ctaColor?.main}`,
      }}
    >
      <Typography variant="h4">Filters</Typography>
      <FilterDropDown
        fieldName="City"
        handleChange={handleChange}
        dropDownItems={cities}
        selectedFilterValue={filters.city}
      />

      <FilterDropDown
        fieldName="Venue"
        handleChange={handleChange}
        dropDownItems={venues}
        selectedFilterValue={filters.venue}
      />
      {!showTagHeader && (
        <FilterDropDown
          fieldName="Tag"
          handleChange={handleChange}
          dropDownItems={tags}
          selectedFilterValue={filters.tag}
        />
      )}
      <FilterDropDown
        fieldName="Artist"
        handleChange={handleChange}
        dropDownItems={artists}
        selectedFilterValue={filters.artist}
      />

      <TextField
        label="Event Date"
        type="date"
        name="date"
        value={filters.date}
        onChange={handleChange}
        sx={{
          mb: 2,
          ml: 0.1,
          mr: 0.1,
          '& .MuiInputBase-root': {
            backgroundColor: '#f5f5f5',
            borderRadius: '4px',
            borderColor: '#d9d9d9',
            '&:before, &:after': {
              borderBottom: 'none',
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '1px solid #d9d9d9',
            },
            '&:hover fieldset': {
              borderColor: '#b3b3b3',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#b3b3b3',
            },
          },
          '& .MuiInputLabel-root': {
            fontFamily: '"kon-tiki-aloha-jf", sans-serif',
            color: '#000',
            '&.Mui-focused': {
              color: '#000',
            },
          },
          '& .MuiInputBase-input': {
            fontFamily: '"kon-tiki-aloha-jf", sans-serif',
            color: '#000',
            padding: '12px 14px',
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {showTagHeader ? (
        <Button
          key=""
          variant="contained"
          sx={{
            color: (theme) => theme.palette.text.primary,
            fontFamily: '"kon-tiki-aloha-jf", sans-serif',
            mb: 2,
            ml: 0.1,
            mr: 0.1,
            textAlign: 'right',
            padding: 1,
          }}
          href="/"
        >
          Reset
        </Button>
      ) : (
        <Button
          key=""
          variant="contained"
          sx={{
            color: (theme) => theme.palette.text.primary,
            fontFamily: '"kon-tiki-aloha-jf", sans-serif',
            mb: 2,
            ml: 0.1,
            mr: 0.1,
            textAlign: 'right',
            padding: 1,
          }}
          onClick={handleReset}
        >
          Reset
        </Button>
      )}
    </Box>
  );
};
