import {
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  CardMedia,
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import AboutBackground from './images/AboutBackground.png';
import TopVenues from './components/TopVenues';
import TopArtists from './components/TopArtists';
import { EventsList } from './components/EventsList';
import { CTAButton } from './components/Utils';
export default function AboutUsCard() {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardMedia
              component="img"
              height="300"
              image={AboutBackground}
              alt="Community Gathering"
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ mb: 2 }}>
            <CardContent>
              <Typography
                gutterBottom
                variant="h1"
                component="h1"
                textAlign="center"
              >
                Welcome to RogueValley.Events!
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                Looking for the best events in the Rogue Valley? Look no further
                than our website! We're your go-to source for all the latest
                happenings, whether you're in the mood for live music, cultural
                festivals, or outdoor activities.
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                Our calendar is packed with events that cater to every interest,
                from family-friendly daytime activities to late-night dance
                parties. We pride ourselves on offering a diverse range of
                options that cater to every mood and style.
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                Are you a business owner, artist, promoter, or someone else
                looking to promote an event in the Rogue Valley? Let us know!
                Visit our <RouterLink to="/contact">contact</RouterLink> page to
                submit your event and we'll do our best to get it listed on our
                website.
              </Typography>
              <Typography variant="body2" color="textSecondary" paragraph>
                Our mission is to make it easy for you to plan your next
                adventure in the Rogue Valley. With our user-friendly website
                and comprehensive event listings, you can find exactly what
                you're looking for in just a few clicks. So why wait? Start
                exploring today and discover all the amazing experiences the
                Rogue Valley has to offer!
              </Typography>
            </CardContent>
          </Card>
          <CTAButton
            href="/contact#contact-us"
            text="List Your Event!"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <EventsList thisWeekOnly />
        </Grid>
        <Grid item xs={12} md={6}>
          <TopVenues />
        </Grid>
        <Grid item xs={6} md={6}>
          <TopArtists />
        </Grid>
      </Grid>
    </Container>
  );
}
